import { flushAction, httpAction, processAction } from './actions';
import { requestSelector } from './selectors';
import { deepEquals } from '../../object';
import { getStore } from '../store';

/**
 * @typedef {{
 *   name?: string,
 *   query: Object<string, any>,
 *   body?: string|Blob|Object<string, any>,
 *   pathParams?: Object<string, string>
 * }} HttpOptions
 */

const requestsMatch = (request, method, url, options) =>
  request?.method === method
    && request?.url === url
    && deepEquals(request?.options, options);

export class HttpError2 extends Error {
  constructor(message, { status, method, url, options, response, error, data, dataType }) {
    super(message);

    this.status = status;
    this.method = method;
    this.url = url;
    this.options = options;
    this.response = response;
    this.error = error;
    this.data = data;
    this.dataType = dataType;
  }
}

export const defaultResponseHandler = ({
  method, url, options, response = null, error = null, data = null, dataType = null
}) => {
  if (response?.ok) return;

  throw new HttpError2(response?.statusText || 'Error', response?.status,
    method, url, options, error, data, dataType);
};

export class Http {
  defaultType = 'json';
  getDefaultParam = () => undefined;
  defaultResponseHandler = defaultResponseHandler;
  requestDecorator = a => a;

  /**
   * @param {string} method
   * @param {string} url
   * @param {HttpOptions} options
   * @param {boolean} force
   * @param {function(method:string, url:string, options:HttpOptions, request:Request, response:Response?,
   *     error:Error?, data:*?, dataType:string?)} responseHandler
   * @return {string}
   */
  http = (method, url, options = {}, force = false, responseHandler = undefined) => {
    const { defaultType, getDefaultParam, requestDecorator, defaultResponseHandler } = this;
    const store = getStore();

    if (!store) {
      throw new Error('setStore() must be called before using methods.');
    }

    const name = options.name || `${method}|${url}`;
    delete options.name;

    const decoratedOptions = requestDecorator(options);

    if (!force) {
      const request = this.getRequest(name, null, false, false);

      if (!request?.processed && requestsMatch(request, method, url, decoratedOptions)) {
        // Don't run the second query if there is a matching one already running.
        return name;
      }
    }

    store.dispatch(httpAction(name, {
      method, url, type: decoratedOptions.type || defaultType, options: decoratedOptions,
      getDefaultParam, responseHandler: responseHandler || defaultResponseHandler, force
    }));

    return name;
  };

  get = (url, options = {}, force = false, responseHandler = undefined) =>
    this.http('get', url, options, force, responseHandler);

  post = (url, options, force = false, responseHandler = undefined) =>
    this.http('post', url, options, force, responseHandler);

  put = (url, options, force = false, responseHandler = undefined) =>
    this.http('put', url, options, force, responseHandler)

  delete = (url, options, force = false, responseHandler = undefined) =>
    this.http('delete', url, options, force, responseHandler);

  getRequest = (name, state = null, unprocessedOnly = true, processOnRetrieval = true) => {
    if (!name) return undefined;

    const request = requestSelector(state || getStore().getState(), name);

    if (unprocessedOnly && request?.processed) return undefined;

    request && request.completed && processOnRetrieval && this.processRequest(name);

    return request;
  };

  flush = nameOrRequestObject => {
    getStore().dispatch(flushAction(nameOrRequestObject.name || nameOrRequestObject));
  }

  processRequest = name => {
    getStore().dispatch(processAction(name));
  };
}

const http = new Http();

export default http;