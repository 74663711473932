const freezableTypes = ['object', 'function'];

const deepFreeze = obj => {
  if (Object.isFrozen(obj) || !freezableTypes.includes(typeof obj)) {
    return obj;
  }

  if (Symbol.iterator in obj) {
    return Object.freeze(Array.prototype.map.call(obj, value => deepFreeze(value)));
  }

  return Object.freeze(
    Object.entries(obj).reduce(
      (result, [key, value]) =>
        Object.assign(result, { [key]: deepFreeze(value) }),
      {}
    )
  );
};

export default deepFreeze;